import '../css/app.pcss'
import router from './router'
import Nav from './components/Nav'
import ContactModal from './components/ContactModal'
import { gsap } from 'gsap/all'
import Swup from './components/Swup'

const nav = new Nav(document.querySelector('.navigation'))
nav.init()
const modal = new ContactModal()
modal.init()
Swup(nav)
window.addEventListener('load', () => {
  router()
  gsap.to('.preloader', {
    opacity: 0,
    duration: 0.6,
    delay: 0.2,
    ease: 'power2.out',
    onComplete: () => document.querySelector('.preloader').remove()
  })
})
