import { gsap, ScrollTrigger } from 'gsap/all'
import { disablePageScroll, enablePageScroll, addFillGapTarget } from 'scroll-lock'
gsap.registerPlugin(ScrollTrigger)

class Nav {
  constructor(el){
    this.el = el
    this.menu = this.el.querySelector('.menu-wrapper')
    this.menuItems = this.menu.querySelectorAll('.menu-item')
    this.menuImage = this.menu.querySelector('.menu-image')
    this.menuButton = this.el.querySelector('#menu-button')
    this.open = false
  }

  init = () => {
    this.showHideTopBar()
    this.menuButton.addEventListener('click', this.menuToggle)

    addFillGapTarget(this.menu)
    addFillGapTarget(this.el)
  }

  showHideTopBar = () => {
    ScrollTrigger.create({
      trigger: document.body,
      start: '72px top',
      end: 99999,
      onUpdate: ({direction, isActive}) => {
        if(direction == 1){
          this.el.classList.add('-translate-y-full')
        }else{
          this.el.classList.remove('-translate-y-full')
        }

        if(isActive){
          this.el.classList.add('!pt-0')
          if(!this.el.classList.contains('fixed')){
            window.setTimeout(() => {
              this.el.classList.add('fixed', 'bg-black', 'stick')
            }, 200)
          }
        }else{
          this.el.classList.remove('!pt-0', 'bg-black', 'fixed', 'stick')
        }
      }
    })
  }

  menuToggle = () => {
    if(this.open) this.closeMenu()
    else this.openMenu()
  }

  openMenu = () => {
    this.open = true
    this.menuButton.classList.add('open')
    const timeline = gsap.timeline({ 
      onStart: () => {
        this.menu.classList.remove('hidden')
        disablePageScroll()
        this.menuItems.forEach(item => item.classList.remove('transition-opacity'))
      },
      onComplete: () => {
        this.menuItems.forEach(item => item.classList.add('transition-opacity'))
        gsap.set([this.menu, this.menuItems, this.menuImage], { clearProps: 'scale,y,opacity' })
        timeline.kill()
      }
    })

    timeline.from(this.menu, {
      scale: 0.9,
      opacity: 0,
      ease: 'power2.out',
      duration: 0.3
    })

    timeline.from(this.menuItems, {
      y: 150,
      opacity: 0,
      ease: 'power2.out',
      stagger: 0.015,
      duration: 0.15
    }, '<0.2')

    timeline.from(this.menuImage, {
      y: 150,
      opacity: 0,
      ease: 'power2.out',
      duration: 0.3
    }, '<0.1')
  }

  closeMenu = () => {
    this.open = false
    this.menuButton.classList.remove('open')
    const timeline = gsap.timeline({ 
      onComplete: () => {
        this.menu.classList.add('hidden')
        enablePageScroll()
        this.menuItems.forEach(item => item.classList.add('transition-opacity'))
        gsap.set([this.menu, this.menuItems, this.menuImage], { clearProps: 'scale,y,opacity' })
        timeline.kill()
      },
      onStart: () => {
        this.menuItems.forEach(item => item.classList.remove('transition-opacity'))
      }
    })

    timeline.to(this.menuItems, {
      y: -150,
      opacity: 0,
      ease: 'power2.out',
      stagger: 0.015,
      duration: 0.15
    })

    timeline.to(this.menuImage, {
      y: -150,
      opacity: 0,
      ease: 'power2.out',
      duration: 0.3
    }, '<0.1')

    timeline.to(this.menu, {
      scale: 0.9,
      opacity: 0,
      ease: 'power2.out',
      duration: 0.3
    }, '<0.1')
  }
}

export default Nav