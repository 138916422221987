import Form from './Form'
import { gsap, ScrollToPlugin } from 'gsap/all'
import axios from 'axios'
import { disablePageScroll, enablePageScroll, addFillGapTarget } from 'scroll-lock'

gsap.registerPlugin(ScrollToPlugin)

class ContactModal {
  constructor(){
    this.modal = document.querySelector('.modal')
  }

  init = () => {
    window.addEventListener('hashchange', this.handleHashChange)
    this.handleHashChange()
  }

  handleHashChange = async (e) => {
    const hash = window.location.hash.substring(1)
    if(hash === 'contact'){
      this.id = document.querySelector('main').dataset.id

      try {
        gsap.from(this.modal, {
          autoAlpha: 0,
          ease: 'power2.out',
          duration: 0.2,
          onStart: () => this.modal.classList.remove('hidden')
        })
        
        const { data } = await axios({
          method: 'GET',
          url: `/dynamic/contact/${this.id}`,
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        })

        this.processResponse(data)
      }catch(err){
        console.log(err)
      }
    }else if(hash === 'team'){
      gsap.to(window, {
        scrollTo: { y: '.team-list', offsetY: 40 },
        ease: 'power2',
        duration: 0.3
      })
    }
  }

  processResponse = res => {
    disablePageScroll()
    addFillGapTarget(this.modal)

    document.querySelector('.modal-inner').insertAdjacentHTML('beforeEnd', res)
    this.close = this.modal.querySelector('.close')
    this.form = this.modal.querySelector('.dynamic-form')

    new Form(this.form).init()
    this.close.addEventListener('click', this.closeModal)

    gsap.from(this.modal.querySelector('.inner'), {
      y: 150,
      autoAlpha: 0,
      ease: 'power2.out',
      duration: 0.2
    })
  }

  closeModal = () => {
    this.close.removeEventListener('click', this.closeModal)
    enablePageScroll()

    const timeline = gsap.timeline({ onComplete: () => {
      this.modal.querySelector('.inner').remove()
      this.modal.classList.add('hidden')
      gsap.set(this.modal, { clearProps: 'all' })
      window.location.hash = ''
    } })
    
    timeline.to(this.modal.querySelector('.inner'), {
      y: -150,
      autoAlpha: 0,
      ease: 'power2.out',
      duration: 0.2
    })

    timeline.to(this.modal, {
      autoAlpha: 0,
      ease: 'power2.out',
      duration: 0.2
    }, '<0.1')
  }
}

export default ContactModal