import axios from "axios";
import _ from "lodash";
import gsap from "gsap/gsap-core";

class Form {
  constructor(form) {
    this.form = form;
    this.message = this.form.parentElement.querySelector(".message.success");
  }

  init = () => {
    this.form
      .querySelector("#submit-form")
      .addEventListener("click", this.submitHandler);
    this.form
      .querySelectorAll("input, select, textarea")
      .forEach((el) => el.addEventListener("blur", this.blurHandler));
    this.message.anim = gsap.from(this.message, {
      opacity: 0,
      y: 150,
      onStart: () =>
        this.message.classList.remove("hidden", "pointer-events-none"),
      paused: true,
    });
  };

  blurHandler = (e) => {
    const fieldWrapper = e.currentTarget.parentElement;
    fieldWrapper.classList.remove("error");

    const error = fieldWrapper.querySelector(".error-message");
    if (error) error.remove();
  };

  submitHandler = async (e) => {
    e.preventDefault();
    const siteKey = e.currentTarget.dataset.sitekey;
    console.log(siteKey);

    grecaptcha.ready(() => {
      grecaptcha.execute(siteKey, { action: "submit" }).then(async (token) => {
        // Add your logic to submit to your backend server here.
        const formData = new FormData(this.form);
        formData.append("g-recaptcha-response", token);

        const { data } = await axios({
          method: "post",
          url: "/",
          data: formData,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        });

        if (data) {
          if (data.errors) this.errorHandler(data.errors);
          else if (data.success) this.successHandler(data);
          else alert("Something went wrong, please try again");
        } else {
          alert("Something went wrong, please try again");
        }
      });
    });
  };

  errorHandler = (errors) => {
    _.forEach(errors, (error, key) => {
      const errorField = this.form.querySelector(`[name*="${key}"]`);
      if (errorField) {
        this.form
          .querySelectorAll(".error-message")
          .forEach((el) => el.remove());
        const fieldWrapper = errorField.parentElement;
        fieldWrapper.classList.add("error");
        fieldWrapper.insertAdjacentHTML(
          "beforeend",
          `<div class="small-body-text text-brownish-red error-message">${error.join(
            ", "
          )}</div>`
        );
      }
    });
  };

  successHandler = (data) => {
    gsap.to(this.form, {
      opacity: 0,
      y: 150,
      onComplete: () => {
        this.form.remove();
        this.message.anim.play();
      },
    });
  };
}

export default Form;
