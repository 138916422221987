import Swup from 'swup'
import SwupJsPlugin from '@swup/js-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import { gsap } from 'gsap/all'
import router from '../router'
import imagesLoaded from 'imagesloaded'

let route = null
gsap.defaults({
  duration: 0.3,
  ease: 'power2'
})
export default (menu) => {
  // const preloader = document.querySelector('.preloader')
  const options = [
    {
      from: '(.*)', // meaning any
      to: '(.*)', // meaning any
      out: (next) => {
        menu.closeMenu()
        gsap.to(document.querySelector('.content-main'),{
          y: -150,
          opacity: 0,
          ease: 'power2.in',
          duration: 0.2,
          onComplete: next
        })
      },
      in: (next) => {
        const content = document.querySelector('.content-main')
        content.classList.add('invisible')
        imagesLoaded('.content-main', async () => {
          route = await router()
          
          gsap.from(content,{
            y: 150,
            opacity: 0,
            ease: 'power2.out',
            duration: 0.2,
            onComplete: next,
            clearProps: 'all',
            onStart: () => content.classList.remove('invisible')
          })
        })
      }
    }
  ]

  const swup = new Swup({
    linkSelector: 'a[href^="/"]:not([data-no-swup]), a[href^="' + window.location.origin + '"]:not([data-no-swup])',
    plugins: [new SwupJsPlugin(options), new SwupScrollPlugin({
      doScrollingRightAway: false,
      animateScroll: false
    })],
    containers: ['.content-main'],
    requestHeaders: {
      "X-Requested-With": "XMLHttpRequest"
    },
    cache: false
  });
}